export enum OrganizationFeatures {
  ShowHiddenFees = "showHiddenFees",
  TicketTransfers = "ticketTransfers",
  DigitalEvents = "digitalEvents",
  VaccinePassport = "vaccinePassport",
  Tags = "tags",
  Points = "points",
  Fiji = "fiji",
  PinPaymentGateway = "pinPaymentGateway",
  DisablePageLoadTracing = "disablePageLoadTracing",
  TicketResale = "ticketResale",
  ResaleTicketPriceCollection = "resaleTicketPriceCollection",
  ResaleTicketQuantityColection = "resaleTicketQuantityCollection",
  SMSMessageMedia = "smsMessageMedia",
}
