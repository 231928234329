import React, { FC, useEffect, useRef, useState } from "react";
import NextImage, { ImageProps } from "next/future/image";
import { getImage } from "~lib";
import { SystemProps, system } from "flicket-ui";
import { ExtendedFile } from "~graphql/sdk";
import styled from "styled-components";
import { omit, pick } from "@styled-system/props";
import { ImageWrapper } from "../common.ImageWrapper";

export interface ImageWrapperProps extends SystemProps {
  image: ExtendedFile;
  alt: string;
  fallback?: string;
  style?: React.CSSProperties;
}

export const StyledDiv = styled.div<SystemProps>`
  overflow: hidden;
  ${system}
`;

const hasMetaData = (bannerImage: ExtendedFile) =>
  bannerImage?.metaData?.width && bannerImage?.metaData?.height;

// This component uses next/image under the hood to render the image.
// The component works with images that have metadata, or it reads the
// width of the current container and uses that to supply to next/image
const Image: FC<ImageWrapperProps> = ({
  image,
  alt,
  fallback = "",
  style = {},
  ...rest
}) => {
  const initialMetaData = hasMetaData(image)
    ? { width: image?.metaData?.width, height: image?.metaData?.height }
    : undefined;

  const wrapper = useRef<HTMLDivElement>();
  const [contentSize, setContentSize] = useState<{
    width: number;
    height: number;
  }>(initialMetaData);

  useEffect(() => {
    if (wrapper.current) {
      setContentSize({
        width: wrapper.current.offsetWidth,
        height: wrapper.current.offsetHeight,
      });
    }
  }, [wrapper.current]);

  return (
    <StyledDiv ref={wrapper} {...pick(rest)}>
      {contentSize && (
        <NextImage
          width={contentSize.width}
          height={contentSize.height}
          style={{
            width: "100%",
            height: "auto",
            ...style,
          }}
          src={getImage(image, fallback)}
          alt={alt}
          {...omit(rest)}
        />
      )}
    </StyledDiv>
  );
};

interface FixedSizeImageProps
  extends Omit<ImageProps, "src">,
    Omit<SystemProps, "color" | "fill" | "position"> {
  image: ExtendedFile | string;
  width: number;
  height: number;
  position?: "left" | "center" | "right" | "top" | "bottom";
  fallback?: string;
}

function FixedSizeImage({
  image,
  alt,
  fallback = "",
  width,
  height,
  position = "left",
  ...props
}: FixedSizeImageProps) {
  return (
    <ImageWrapper width={width} height={height} {...pick(props)}>
      <NextImage
        src={getImage(image, fallback)}
        alt={alt}
        fill={true}
        style={{ objectFit: "contain", objectPosition: position }}
        {...omit(props)}
      />
    </ImageWrapper>
  );
}

export default Image;
export { FixedSizeImage };
