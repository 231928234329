import { atom } from "jotai";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { isVisible } from "~components/reservation/non-seated/non-seated.helpers";
import useIsGAInitialised from "~features/analytics/useIsGAInitialised";
import { INormalisedData } from "~features/nonseated-reservation/nonseated-reservation.types";
import { Release } from "~graphql/fetchers";
import {
  CreateOrderMutation,
  CurrentOrganizationQuery,
  MembershipQuery,
  MembershipsSoldForUserQuery,
  OrderQuery,
  TicketsSoldForUserQuery,
} from "~graphql/sdk";
import { PageName } from "~telemetry/enums/AttributeNames";

declare interface DataLayerWindow extends Window {
  GA_INITIALIZED?: boolean;
}
declare const window: DataLayerWindow;

export const googleAnalyticsReleaseAtom = atom<Release | undefined>(undefined);
export const googleAnalyticsMembershipAtom = atom<
  MembershipQuery["membership"] | undefined
>(undefined);

export const useGoogleAnalyticsPageView = (
  fireEvent: boolean,
  pageName: PageName,
  eventId?: string
) => {
  const isGAInitialised = useIsGAInitialised();

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      typeof window !== "undefined" &&
      isGAInitialised &&
      fireEvent
    ) {
      ReactGA.send({
        hitType: "pageview",
        event_id: eventId,
        page_name: pageName,
      });
    }
  }, [isGAInitialised, eventId, fireEvent]);
};

const track = (eventName: string, eventData) => {
  const isWindowAvailable = typeof window !== "undefined";
  const isGAInitialized = isWindowAvailable && window.GA_INITIALIZED;
  if (isGAInitialized) {
    ReactGA.event(eventName, eventData);
  }
};

export const trackSelectItemV4 = (data: {
  eventOrMembershipId: string;
  item;
  release?: Release;
  membership?: MembershipQuery["membership"];
}) => {
  const { eventOrMembershipId, item, membership, release } = data;

  track("select_item", {
    event_id: eventOrMembershipId,
    items: [
      {
        item_id: item.id,
        item_name: item.name,
        affiliation: release?.event.title ?? membership?.name,
        item_list_id: release?.id,
        item_list_name: release?.name,
        item_category: item.zoneId,
        quantity: item.quantity,
        price: item.price,
      },
    ],
  });
};

export const trackPurchaseV4 = (
  organization: CurrentOrganizationQuery["currentOrganization"],
  order: OrderQuery["order"]
) => {
  track("purchase", {
    event_id: order?.event?.id || order?.membership?.id,
    currency: organization?.currency,
    transaction_id: order?.id,
    value: order?.total,
    items: order?.lineItems?.edges
      ?.filter(({ node }) => node?.total > 0)
      ?.map(({ node }) => ({
        item_id:
          node.ticketType?.id ||
          node.membershipType?.id ||
          node.eventAddon?.id ||
          node.membershipAddon?.id,
        item_name:
          node.ticketType?.name ||
          node.membershipType?.membership?.name ||
          node.name,
        affiliation: order?.event?.title || order?.membership?.name,
        item_list_id: order.release?.id,
        item_list_name: order.release?.slug,
        quantity: node?.quantity,
        item_category: node?.seatZone,
        price: node?.price,
        shipping: order?.deliveryFee,
        tax: order?.totalTax,
      })),
  });
};

export const trackRemoveFromCartV4 = (
  organization: CurrentOrganizationQuery["currentOrganization"],
  data: {
    eventOrMembershipId: string;
    item;
    release?: Release;
    membership?: MembershipQuery["membership"];
  }
) => {
  const { eventOrMembershipId, item, membership, release } = data;

  track("remove_from_cart", {
    event_id: eventOrMembershipId,
    currency: organization.currency,
    value: item.quantity * item.price,
    items: [
      {
        item_id: item.id,
        item_name: item.name,
        affiliation: release?.event.title ?? membership?.name,
        item_list_id: release?.id,
        item_list_name: release?.name,
        item_category: item.zoneId,
        quantity: item.quantity,
        price: item.price,
      },
    ],
  });
};

export const trackCheckoutV4 = (
  organization: CurrentOrganizationQuery["currentOrganization"],
  order: CreateOrderMutation["createOrder"]["order"]
) => {
  track("begin_checkout", {
    event_id: order?.event?.id || order?.membership?.id,
    value: order?.total,
    currency: organization?.currency,
    items: order?.lineItems?.edges
      ?.filter(({ node }) => node?.total > 0)
      ?.map(({ node }) => ({
        item_id:
          node.ticketType?.id ||
          node.membershipType?.id ||
          node.eventAddon?.id ||
          node.membershipAddon?.id,
        item_name:
          node.ticketType?.name ||
          node.membershipType?.membership?.name ||
          node.name,
        affiliation: order?.event?.title ?? order?.membership?.name,
        item_list_id: order.release?.id,
        item_list_name: order.release?.slug,
        quantity: node?.quantity,
        item_category: node?.seatZone,
        price: node?.price,
      })),
  });
};

export const trackAddToCartV4 = (
  organization: CurrentOrganizationQuery["currentOrganization"],
  data: {
    eventOrMembershipId: string;
    item;
    release?: Release;
    membership?: MembershipQuery["membership"];
  }
) => {
  const { eventOrMembershipId, item, membership, release } = data;

  track("add_to_cart", {
    event_id: eventOrMembershipId,
    currency: organization?.currency,
    value: item.price * item.quantity,
    items: [
      {
        item_id: item.id,
        item_name: item.name,
        affiliation: release?.event.title ?? membership?.name,
        item_list_id: release?.id,
        item_list_name: release?.name,
        item_category: item.zoneId,
        quantity: item.quantity,
        price: item.price,
      },
    ],
  });
};

export const trackViewItemListV4 = (
  eventOrMembership: INormalisedData | MembershipQuery["membership"],
  data: {
    zones;
    ticketTypes;
    seats;
    ticketsSoldForUser:
      | TicketsSoldForUserQuery["ticketsSoldForUser"]
      | MembershipsSoldForUserQuery["membershipsSoldForUser"];
    isAdmin: boolean;
    addonTypes;
    release: Release;
  }
) => {
  const {
    zones,
    ticketTypes,
    seats,
    ticketsSoldForUser,
    isAdmin,
    release,
    addonTypes,
  } = data;

  let index = 0;

  const tickets = zones?.flatMap((zone) => {
    if (!zone.isActive) {
      return null;
    }
    return ticketTypes?.map((ticketType) => {
      const zoneTypes = zone?.ticketTypes ?? zone?.membershipTypes;
      const zoneTicketType = zoneTypes?.find(
        ({ ticketTypeId, membershipTypeId }) =>
          ticketTypeId === ticketType.id || membershipTypeId === ticketType.id
      );

      if (
        !zoneTicketType ||
        !zoneTicketType.isActive ||
        (!isVisible(seats, ticketType, ticketsSoldForUser) && !isAdmin)
      ) {
        return null;
      }

      index += 1;

      return {
        name: `${zone?.name ?? ""} - ${ticketType?.name ?? ""}`,
        price: zoneTicketType.price,
        zoneId: zone?.name,
        index,
        ...ticketType,
      };
    });
  });

  let items = [...addonTypes];
  tickets?.forEach((element) => {
    items.push(element);
  });

  items = items.filter((item) => item?.name.length > 0);

  const name = eventOrMembership.name;

  track("view_item_list", {
    event_id: eventOrMembership.id,
    item_list_id: eventOrMembership.id,
    item_list_name: name,
    items: items.map((item) => ({
      item_id: item.id,
      item_name: item.name,
      index: item.index,
      item_category: item.zoneId,
      item_list_name: release?.name,
      item_list_id: release?.id,
      price: item.price,
    })),
  });
};

export const trackViewItemV4 = (
  organization: CurrentOrganizationQuery["currentOrganization"],
  data: {
    seated: boolean;
    item;
    release?: Release;
    membership?: MembershipQuery["membership"];
    // used by seated membership
    price?: number;
  }
) => {
  const { item, seated, release, membership, price } = data;

  const getTransformedItem = (seated: boolean) => {
    const affiliation = release?.event?.title ?? membership?.name;
    if (!seated) {
      return {
        item_id: item.id,
        item_name: item.name,
        affiliation,
        item_category: item.zoneId,
        item_list_name: release?.name,
        item_list_id: release?.id,
        price: item.price,
      };
    } else {
      return {
        item_id: item.ticketType.value,
        item_name: item.ticketType.label,
        affiliation,
        item_category: item.category?.label,
        item_list_name: release?.name,
        item_list_id: release?.id,
        price: item.price ?? price,
      };
    }
  };

  const eventOrMembershipId = release?.event?.id ?? membership?.id;

  track("view_item", {
    event_id: eventOrMembershipId,
    currency: organization?.currency,
    value: item.price ?? price,
    items: [getTransformedItem(seated)],
  });
};
